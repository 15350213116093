<script setup>
const emit = defineEmits(["close"]);

const props = defineProps({
  state: { type: String, default: "primary" },
  icon: { type: String },
  dismissable: { type: Boolean },
  customButton: { type: String },
  customButtonAction: { type: Function },
});

function closeToast() {
  emit("close");
}
</script>
<template>
  <div id="chi-toast" class="chi-toast">
    <div class="left-icon" v-if="icon" :class="props.state">
      <nuxt-icon class="icon" :name="props.icon" />
    </div>
    <div class="text-container">
      <span
        class="text-style-body-14-regular"
        style="color: var(--color-neutral-900-aaa-text-darkest)"
      >
        <slot
      /></span>
    </div>
    <BaseChButton
      v-if="props.customButton"
      design="text"
      size="small"
      @click="props.customButtonAction"
      style="padding: 4px 8px"
      :label="props.customButton"
    ></BaseChButton>
    <BaseChButton
      v-if="props.dismissable"
      design="text"
      icon="/close.svg"
      size="small"
      @click="closeToast"
    ></BaseChButton>
  </div>
</template>
<style scoped lang="scss">
$types: primary, positive, critical, neutral, promote;

.chi-toast {
  padding-right: 4px;
  border-radius: 8px;
  background: var(--color-f, #fff);
  /* Elevation/02 - Menus & Modals */
  box-shadow: 0px 16px 32px 0px rgba(29, 33, 45, 0.1),
    0px 1px 4px 0px rgba(29, 33, 45, 0.15),
    0px 0px 1px 0px rgba(29, 33, 45, 0.2);
  outline: none;
  border: none;
  width: fit-content;
  min-width: 350px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;

  & .text-container {
    width: 100%;
    height: 100%;
    padding: 8px 16px;
  }
  & .left-icon {
    width: 40px;
    min-width: 40px;
    height: 100%;
    overflow: hidden;
    border-radius: 8px 0 0 8px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @each $type in $types {
    & .left-icon.#{$type} {
      background: var(--color-#{$type}-200-light-border);
      color: var(--color-#{$type}-500-graphics-large-aa-text);
    }
  }
}
</style>
